import React from "react";
const AdminHome = React.lazy(() => import("./Home/Index.js"));
const AdminBaner = React.lazy(() => import("./Baners/Index.js"));
const AdminBanerAdd = React.lazy(() => import("./BanerAdd/Index.js"));
const AdminUsers = React.lazy(() => import("./Users/Index.js"));
const AdminUser = React.lazy(() => import("./User/Index.js"));
const AdminOrders = React.lazy(() => import("./Orders/Index.js"));
const AdminOrder = React.lazy(() => import("./Order/Index.js"));
const AdminCategories = React.lazy(() => import("./Categories/Index.js"));
const AdminCategoriesAdd = React.lazy(() => import("./CategoriesAdd/Index.js"));
const AdminCategory = React.lazy(() => import("./Category/Index.js"));
const AdminBilling = React.lazy(() => import("./Billing/Index.js"));
const AdminProducts = React.lazy(() => import("./Products/Index.js"));
const AdminProduct = React.lazy(() => import("./Product/Index.js"));
const AdminProductAdd = React.lazy(() => import("./ProductAdd/Index.js"));
const AdminAtributes = React.lazy(() => import("./Atributes/Index.js"));
const AdminBrands = React.lazy(() => import("./Brands/Index.js"));
const AdminSliderText = React.lazy(() => import("./SliderText/Index.js"));
const AdminInfluencerAdd = React.lazy(() => import("./InfluencerAdd/Index.js"));
const AdminInfluencers = React.lazy(() => import("./Influencers/Index.js"));
const AdminInfluencer = React.lazy(() => import("./Influencer/Index.js"));
const AdminDiscounts = React.lazy(() => import("./Discounts/Index.js"));
const AdminMysteryBoxes = React.lazy(() => import("./MysteryBoxes/Index.js"));
const AdminMysteryBoxAdd = React.lazy(() => import("./MysteryBoxAdd/Index.js"));
const AdminMysteryBox = React.lazy(() => import("./MysteryBox/Index.js"));
const AdminMysteryBoxProducts = React.lazy(() =>
  import("./MysteryBoxProducts/Index.js")
);
const AdminMysteryBoxWinners = React.lazy(() =>
  import("./MysteryBoxWinners/Index.js")
);
const AdminMysteryBoxWinner = React.lazy(() =>
  import("./MysteryBoxWinner/Index.js")
);
const AdminFaqList = React.lazy(() => import("./FaqList/Index.js"));
const AdminFaqAdd = React.lazy(() => import("./FaqAdd/Index.js"));
const AdminFaqUpdate = React.lazy(() => import("./FaqUpdate/Index.js"));
const AdminPartnerzy = React.lazy(() => import("./Partners/Index.js"));
const AdminPartner = React.lazy(() => import("./Partner/Index.js"));
const AdminHiddenCats = React.lazy(() => import("./CategoriesHidden/Index"));

export let admin_routes = [
  {
    path: "/admin",
    element: <AdminHome />,
  },
  {
    path: "/admin/faq",
    element: <AdminFaqList />,
  },
  {
    path: "/admin/faq/dodaj",
    element: <AdminFaqAdd />,
  },
  {
    path: "/admin/influencerzy",
    element: <AdminInfluencers />,
  },
  {
    path: "/admin/faq/:faq_id",
    element: <AdminFaqUpdate />,
  },
  {
    path: "admin/influencerzy/dodaj",
    element: <AdminInfluencerAdd />,
  },
  {
    path: "/admin/influencerzy/:influ_id",
    element: <AdminInfluencer />,
  },
  {
    path: "/admin/baner",
    element: <AdminBaner />,
  },
  {
    path: "/admin/baner/dodaj",
    element: <AdminBanerAdd />,
  },
  {
    path: "/admin/uzytkownicy",
    element: <AdminUsers />,
  },
  {
    path: "/admin/uzytkownicy/:user_id",
    element: <AdminUser />,
  },
  {
    path: "/admin/zamowienia",
    element: <AdminOrders />,
  },
  {
    path: "/admin/zamowienia/:order_id",
    element: <AdminOrder />,
  },
  {
    path: "/admin/kategorie",
    element: <AdminCategories />,
  },
  {
    path: "/admin/kategorieukryte",
    element: <AdminHiddenCats />,
  },
  {
    path: "/admin/kategorie/dodaj",
    element: <AdminCategoriesAdd />,
  },
  {
    path: "/admin/kategorie/:category_id",
    element: <AdminCategory />,
  },
  {
    path: "/admin/rachunki",
    element: <AdminBilling />,
  },
  {
    path: "/admin/produkty",
    element: <AdminProducts />,
  },
  {
    path: "/admin/produkty/dodaj",
    element: <AdminProductAdd />,
  },
  {
    path: "/admin/produkty/:product_id",
    element: <AdminProduct />,
  },
  {
    path: "/admin/atrybuty",
    element: <AdminAtributes />,
  },
  {
    path: "/admin/marki",
    element: <AdminBrands />,
  },
  {
    path: "/admin/slidertext",
    element: <AdminSliderText />,
  },
  {
    path: "/admin/kody-rabatowe",
    element: <AdminDiscounts />,
  },
  {
    path: "/admin/mysteryboxes",
    element: <AdminMysteryBoxes />,
  },
  {
    path: "/admin/mysteryboxes/dodaj",
    element: <AdminMysteryBoxAdd />,
  },
  {
    path: "/admin/mysteryboxes/:roulette_id",
    element: <AdminMysteryBox />,
  },
  {
    path: "/admin/mysteryboxes/:roulette_id/produkty",
    element: <AdminMysteryBoxProducts />,
  },
  {
    path: "/admin/wygrane",
    element: <AdminMysteryBoxWinners />,
  },
  {
    path: "/admin/wygrane/:user_roulette_id",
    element: <AdminMysteryBoxWinner />,
  },
  {
    path: "/admin/partnerzy",
    element: <AdminPartnerzy />,
  },
  {
    path: "/admin/partner/:user_id",
    element: <AdminPartner />,
  },
];
