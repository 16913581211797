import React from "react";
const Home = React.lazy(() => import("./Home/Index"));
const Shop = React.lazy(() => import("./Shop/Index"));
const Product = React.lazy(() => import("./Product/Index"));
const Basket = React.lazy(() => import("./Basket/Index"));
const Checkout = React.lazy(() => import("./Checkout/Index"));
const Privacy = React.lazy(() => import("./Privacy/Index.js"));
const Regulations = React.lazy(() => import("./Regulacy/Index.js"));
const Contact = React.lazy(() => import("./Contact/Index.js"));
const ThankYou = React.lazy(() => import("./ThankYou/Index.js"));
const MysteryBoxes = React.lazy(() => import("./MysteryBoxes/Index.js"));
const MysteryBox = React.lazy(() => import("./MysteryBox/Index.js"));
const MysteryBoxCheckout = React.lazy(() =>
  import("./MysteryBoxCheckout/Index.js")
);
const FAQ = React.lazy(() => import("./FAQ/Index.js"));
const PartnerJoin = React.lazy(() => import("./PartnerJoin/Index.js"));
const NotFound = React.lazy(() => import("./404/Index.js"));

export let core_routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/dziekuje/:order_id",
    element: <ThankYou />,
  },
  {
    path: "/sklep",
    element: <Shop />,
  },
  {
    path: "/sklep/:category_slug",
    element: <Shop />,
  },
  {
    path: "/:product_id/:product_name",
    element: <Product />,
  },
  {
    path: "/koszyk",
    element: <Basket />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/404",
    element: <NotFound />,
  },

  {
    path: "/polityka-prywatnosci",
    element: <Privacy />,
  },
  {
    path: "/regulamin",
    element: <Regulations />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
  },
  {
    path: "/dankycase",
    element: <MysteryBoxes />,
  },
  {
    path: "/program-afiliacyjny",
    element: <PartnerJoin />,
  },
  {
    path: "/dankycase/checkout/:product_id",
    element: <MysteryBoxCheckout />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/dankycase/:product_id/:product_name",
    element: <MysteryBox />,
  },
];
